<template lang="pug">
  v-app
    side-bar(v-model="drawer")
    v-app-bar(app :color="`${theme.colors.primary} darken-3`" dark)
      v-app-bar-nav-icon(@click.stop="drawer = !drawer")
      v-toolbar-title MOL
      v-spacer
      v-toolbar-items
        v-btn(icon @click="logout")
          v-icon logout
    v-main(:class="`${this.theme.colors.primary} lighten-5`")
      v-container(align="start" justify="start" fluid)
        router-view(v-if="user")
      notifications(position="bottom center")
      page-loading(
        loader="dots"
        color="#3f51b5"
        background-color="#000"
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
      )
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import PageLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import SideBar from '@/components/SideBar.vue';
export default {
  components: {
    SideBar,
    PageLoading
  },
  data: () => {
    return {
      drawer: false
    };
  },
  computed: {
    ...mapState({
      user: s => s.auth.user,
      theme: s => s.config.theme
    }),
    ...mapGetters(['isLoading'])
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    })
  }
};
</script>
