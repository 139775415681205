<template lang="pug">
  v-container
    v-row(v-if="labels.length")
      v-col(cols="12")
        h1(class="font-weight-light") Etichete
      v-col(v-for="label in labels" :key="label.label" sm="6" md="4" lg="3" xl="2")
        v-card(:color="label.color" :to="label.route" dark height="100%" class="text-center" flat)
          v-card-text(class="pa-5")
            v-icon(size="75") {{ label.icon }}
            h3(class="text-uppercase mt-5") {{ label.label }}
    v-row(v-if="filteredActions.length")
      v-col(cols="12")
        h1(class="font-weight-light") Actiuni rapide
      v-col(v-for="action in filteredActions" :key="action.label" sm="6" md="4" lg="3" xl="2")
        v-card(color="green" :to="action.route" dark height="100%" class="text-center" flat)
          v-card-text(class="pa-5")
            v-icon(size="75") {{ action.icon }}
            h3(class="text-uppercase mt-5") {{ action.label }}
    v-row(v-if="links.length")
      v-col(cols="12")
        h1(class="font-weight-light") Link-uri utile
      v-col(v-for="link in links" :key="link.label" sm="6" md="4" lg="3" xl="2")
        v-card(color="blue" :href="link.url" dark height="100%" class="text-center" target="_blank" flat)
          v-card-text(class="pa-5")
            v-icon(size="75") link
            h3(class="text-uppercase mt-5") {{ link.label }}
</template>
<script>
import { actions, links, findLabel } from './index.js';
import { mapState } from 'vuex';

export default {
  data () {
    return {
      labels: [],
      links
    };
  },
  computed: {
    ...mapState({
      user: s => s.auth.user
    }),
    filteredActions () {
      return actions.filter(item => item.roles.includes(this.user.role.name));
    }
  },
  mounted () {
    if (this.user.role.name !== 'user') {
      this.loadLabels();
    }
  },
  methods: {
    setLabels (data) {
      this.labels = data.map(label => ({
        label: label.name,
        route: { name: 'labels.index', params: { id: label.id } },
        ...findLabel(label.name)
      }));
    },
    loadLabels () {
      this.$store.commit('pageLoading', true);

      const params = {
        filters: {
          depth: 0
        }
      };

      return this.axios.get('/labels', { params })
        .then(res => {
          this.setLabels(res.data.data);
        })
        .catch(e => {
          this.$error(e);
        }).finally(() => {
          this.$store.commit('pageLoading', false);
        });
    }
  }
};
</script>
