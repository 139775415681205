<template lang="pug">
  v-container
    v-data-table(
      :headers="headers"
      :items="logs"
      :loading="loading"
      :server-items-length="logs.length"
      hide-default-footer
      class="elevation-1"
      disable-sort
    )
      template(#item="{ item }")
        tr
          td
            v-chip(:color="chipColor(item)" dark @click="activateChip(item)")
              v-avatar(left)
                v-icon {{ chipIcon(item) }}
              span {{ item.date }}
          td {{ item.text }}
        tr(v-show="activeChip && activeChip.date === item.date")
          td(colspan="2")
            pre {{ item.stack }}
</template>
<script>
export default {
  data () {
    return {
      loading: false,
      headers: [
        { text: 'Date', value: 'date' },
        { text: 'Message', value: 'text' }
      ],
      logs: [],
      activeChip: null
    };
  },
  mounted () {
    this.loadLogs();
  },
  methods: {
    chipColor (log) {
      switch (log.level) {
        case 'error':
          return 'red';
        case 'emergency':
          return 'red accent-2';
        case 'critical':
          return 'red accent-4';
        case 'warning':
          return 'orange';
        case 'failed':
          return 'orange accent-4';
        default:
          return 'blue';
      }
    },
    chipIcon (log) {
      switch (log.level) {
        case 'error':
        case 'critical':
        case 'emergency':
          return 'error';
        case 'warning':
        case 'failed':
          return 'warning';
        default:
          return 'info';
      }
    },
    activateChip (log) {
      this.activeChip = this.activeChip ? (this.activeChip.date === log.date ? null : log) : log;
    },
    loadLogs () {
      this.loading = true;

      return this.axios.get('/logs')
        .then(res => {
          this.logs = res.data.logs;
          this.files = res.data.files;
        })
        .catch(e => {
          this.$error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
