<template lang="pug">
  v-navigation-drawer(:value="value" @input="$emit('input', $event)" app :color="`${theme.colors.primary} darken-4`" dark)
    template(#prepend)
      v-list-item(v-if="user" two-line)
        v-list-item-avatar(color="red")
          span(class="white--text headline") {{ userInitials }}
        v-list-item-content
          v-list-item-title {{ user.name }}
          v-list-item-subtitle {{ user.email }}
    v-divider
    v-list(v-if="user" dense)
      template(v-for="menuItem in filteredMenuItems")
        v-list-item(:to="menuItem.route" :exact="menuItem.exact")
          v-list-item-action
            v-icon {{ menuItem.icon }}
          v-list-item-content
            v-list-item-title {{ menuItem.label }}
</template>
<script>
import { mapState } from 'vuex';
export default {
  props: {
    value: {
      required: true
    }
  },
  data () {
    return {
      menuItems: [
        {
          label: 'Acasa',
          icon: 'home',
          roles: ['user', 'developer', 'administrator'],
          route: { name: 'dashboard' },
          exact: true
        },
        {
          label: 'Utilizatori',
          icon: 'people',
          roles: ['developer', 'administrator'],
          route: { name: 'users.index' }
        },
        {
          label: 'Institutii',
          icon: 'work',
          roles: ['developer'],
          route: { name: 'institutions.index' }
        },
        {
          label: 'Institutie',
          icon: 'work',
          roles: [],
          route: { name: 'institutions.index' }
        },
        {
          label: 'Etichete',
          icon: 'list',
          roles: ['developer', 'administrator'],
          route: { name: 'labels.index' }
        },
        {
          label: 'Log-uri',
          icon: 'description',
          roles: ['developer'],
          route: { name: 'logs' }
        }
      ]
    };
  },
  computed: {
    ...mapState({
      user: s => s.auth.user,
      theme: s => s.config.theme
    }),
    filteredMenuItems () {
      return this.menuItems.filter(item => item.roles.includes(this.user.role.name));
    },
    userInitials () {
      return this.user.name.split(' ').map(s => s[0]).join('');
    }
  }
};
</script>
