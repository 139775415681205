<template lang="pug">
  div
    v-container(class="py-0")
      v-row
        v-col(cols="12" class="pb-0")
          h1(class="h1 font-normal-light text-uppercase mb-4") {{ user.institution.name }}
          v-divider
    quick-access
</template>
<script>
import QuickAccess from '@/components/QuickAccess/QuickAccess';
import { mapState } from 'vuex';
export default {
  components: { QuickAccess },
  computed: {
    ...mapState({
      user: s => s.auth.user
    })
  }
};
</script>
